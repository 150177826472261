import React, { Component } from 'react';
import Navbar from './Components/Navbar';
import Header from './Components/Header';
import About from './Components/About';
import Footer from './Components/Footer';
import SmoothScroll from './Components/SmoothScroll';
import Team from './Components/Team';
import Expertises from './Components/Expertises';
import Contact from './Components/Contact';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Navbar />
        <Header />
        <About />
        <Expertises />
        <Team />
        <Contact />
        <Footer />
        <SmoothScroll />
      </div>
    );
  }
}

export default App;
