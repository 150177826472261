import React from 'react';

const Contact = () => {
  return (
    <section id="Contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h3>
              
              <a className="text-white text-center" href="mailto:kim.kaisti@itsiak.fi">
                kim.kaisti@itsiak.fi
              </a>
            </h3>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
