import React from 'react';
import Expertise from './Expertise';

const hwPoints = [
  'Antennas, RF, digital, analog, mixed-signal, power electronics, PA’s',
  'PCB, module and tester board design',
  'EMI/EMC pre-compliancy measurements',
  'Wireless technologies (GNSS, BT)',
  '16/24-bit Digital Audio and speaker design',
  'High volume design (1M+ units/a)'
];
const swPoints = [
  'Embedded SW, ANSI-C & assembler (several MCU’s incl. PIC, COP8)',
  'DSP filter design (VSDSP, Nuvoton, Motorola)',
  'Databases (SQL, MariaDB, NoSQL)',
  'Labview',
  'Fullstack, Serverless, AWS, Google Cloud'
];
const toolPoints = [
  'Mentor Graphics PADS (schematic and layout)',
  'Various Spice SW (analog simulation)',
  'Various embedded MCU/DSP development environments',
  'ANSI-C, C#, C++, Python, React, Node.js, Perl',
  'Labview, Matlab, Raspberry Pie, Git'
];
const equipmentPoints = [
  '7GHz spectrum analyzer',
  '6GHz 2-port VNA',
  '4-channel 1Gs/s DPO oscilloscope',
  '2GHz frequency generator',
  'Frequency counters, lab supplies, battery charger simulator, signal generators'
];

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

const Expertises = () => {
  return (
    <section id="Expertise" className={isMobile ? 'projects-section-mobile' : 'projects-section'}>
      <div className="container">
        <Expertise title={'Hardware Expertise'} points={hwPoints} image={'fa-microchip'} isImageLeft={false} />
        <Expertise title={'Software Expertise'} points={swPoints} image={'fa-code'} isImageLeft={true} />
        <Expertise title={'Tools'} points={toolPoints} image={'fa-toolbox'} isImageLeft={false} />
        <Expertise title={'R&D and Design verification'} points={equipmentPoints} image={'fa-signal'} isImageLeft={true} />
      </div>
    </section>
  );
};

export default Expertises;
