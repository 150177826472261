import React from 'react';

const pushIcon = 'order-2 order-md-1 ';
const pushContent = 'flex-column-reverse ';
const pullIcon = 'order-2';
const pullContent = ' order-1 order-md-2 ';

const Image = (isImageLeft, image) => {
  return (
    <div className={'text-center col-sm-12 col-md-6 col-xl-6 col-lg-6 ' + (isImageLeft ? pushIcon : pullIcon)}>
      {/* <img className="img-fluid mb-3 mb-lg-0" src={'img/' + image} alt="" /> */}
      <i className={'icon fas text-white sr-icon-1 ' + image} />
    </div>
  );
};

const Content = (isImageLeft, title, points) => {
  return (
    <div className={'col-sm-12 col-md-6 col-xl-6 col-lg-6 ' + (isImageLeft ? pullContent : pushContent)}>
      <div className="project-text text-white w-100 my-auto text-center text-lg-left">
        <h4>{title}</h4>
        <ul className="list-group" />
        {points.map((item, i) => {
          return (
            <li key={i} className="list-group-item">
              {item}
            </li>
          );
        })}
      </div>
    </div>
  );
};

const Expertise = ({ isImageLeft, image, title, points }) => (
  <div className="row align-items-center no-gutters mb-4 mb-lg-5">
    {isImageLeft ? Image(isImageLeft, image) : Content(isImageLeft, title, points)}
    {isImageLeft ? Content(isImageLeft, title, points) : Image(isImageLeft, image)}
  </div>
);
export default Expertise;
