import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
      <section id="About" className="about-section text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <h2 className="text-white mb-4">Hardware and Software expertise</h2>
              <p className="text-white-50">Broad range of hardware and software design expertise</p>
            </div>
          </div>
          <img src="img/demo.png" className="img-fluid" alt="" />
        </div>
      </section>
    );
  }
}

export default About;
