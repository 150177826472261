import { Component } from 'react';

class SmoothScroll extends Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'js/SmoothScrollJQuery.js';
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return null;
  }
}

export default SmoothScroll;
