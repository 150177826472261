import React, { Component } from 'react';

class Team extends Component {
  render() {
    return (
      <section id="Team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="text-white mb-4">Contact</h2>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Team;
