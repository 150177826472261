import React, { Component } from 'react';

class Header extends Component {
  render() {
    return (
      <header className="masthead">
        <div className="container d-flex h-100 align-items-center">
          <div className="mx-auto text-center">
            <img src="img/logo.png" className="img-fluid" alt="" />
            {/* <h1 className="inverted mx-auto my-0 text-uppercase">KAISTI</h1> */}
            <h2 className="customh2 text-white-100 mx-auto mt-2 mb-5">30+ years of cumulative design expertise</h2>
            <a href="#About" className="btn btn-primary js-scroll-trigger">
              Learn More
            </a>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
