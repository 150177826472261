import React from 'react';

const NavbarItem = ({ title }) => (
  <li className="nav-item">
    <a className="customLink nav-link js-scroll-trigger" href={'#' + title}>
      {title}
    </a>
  </li>
);
export default NavbarItem;
